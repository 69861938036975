ul.share-buttons {
  list-style: none;
  padding: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

ul.share-buttons {
  li {
    margin-right: 0.8rem;
  }

  li, img {
    display: inline;
  }
}

#cse-search-box {
  position: relative;
  margin: 1rem 0;

  [name="q"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-transition: border-color;
    -moz-transition: border-color;
    transition: border-color;
    background-color: white;
    border: 1px solid gainsboro;
    box-sizing: border-box;
    display: block;
    font-size: 0.8em;
    margin: 0;
    padding: 0.5em;
    position: relative;
    width: 100%;
  }

  [name="sa"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    position: absolute;
    top: 0em;
    right: 0em;
    bottom: 0em;
    outline: none;
    padding: 5px 10px;
    border: none;
    background: lightgrey;

    img {
      height: 1.1rem;
      opacity: 0.7;
    }
  }
}

nav.sidebar-nav ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 0;

  li a {
    display: inline;
    color: #ffd700;
  }
}

.page, .post {
  margin-bottom: 2rem;
}

@media (min-width: 30em) {
  .pagination {
    margin: 2rem 0;
  }
}

.post-title a {
  color: #268bd2;
}

.related {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.social-networks {
  padding-top: 1.5rem;

  li {
    display: inline-block;
    margin-right: 1.5rem;

    a:hover {
      text-decoration: none;
    }
  }
}

.sidebar-about h1 img {
  margin-left: auto;
  margin-right: auto;
}

.aloha {
  margin-bottom: 1rem;
}

.sidebar-aloha {
  height: 200px;
  text-align: center;
}

table th {
  text-align: left;
}

/* .kaklabs { */
/*   display: block; */
/*   text-align: center; */
/*   font-size: 12px; */
/*   padding-top: 10px; */
/* } */
/*  */
/* .kaklabs-love { */
/*   color: red; */
/* } */

#msg {
  display: none;
  margin-bottom: 30px;
  padding: 20px 10px;
  background: #D30000;
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}

.google-map {
  width: 100%;
  height: 300px;
}

.batik {
  visibility:hidden;
  opacity:0;
  transition:visibility 0.4s linear,opacity 0.4s linear;
}

.batik-visible {
  visibility: visible;
  opacity: 1;
}

.tag {
  list-style: none;
  padding-left: 0;

  &__item {
    display: inline-block;
    margin-right: 0.75rem;
  }
}

.category {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0.5rem;
  color: #9a9a9a;

  &__item, &__label {
    display: inline-block;
    margin-right: 0.5rem;
  }

  &__item:not(:last-of-type)::after {
    content: ', '
  }
}

.active {
  text-decoration: underline;
}

#gcw_siteF9xGVUSR5 {
  color: white;
}

.copyright {
  font-size: 12px;
}

.video-wrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.other {
  list-style: none;
  padding-left: 0;

  &__list {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #268bd2;
  }
}

.green {
  color: #19a974;
}

.red {
  color: #ff4136;
}

.price {
  font-size: small;
}

// hero and info boxes

.hero-section {
  background: linear-gradient(135deg, rgba(255,255,255,0.7), lightgoldenrodyellow);
  color: #515151;
  text-align: center;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin-bottom: 1rem;
  border-radius: .5rem;
}

.contact a {
  margin-right: 1rem;
}

.info-boxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.box {
  background: white;
  padding: 1rem;
  width: 46%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  text-align: center;
  border-radius: .5rem;
  margin-bottom: 1rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.box h2 {
  font-size: 1.25rem;
}

.box p {
  font-size: .75rem;
}

.cta-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #268bd2;
  color: #fff;
  text-decoration: none;
  border-radius: .5rem;
  cursor: pointer;
  font-size: small;
}

.cta-button:hover {
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .box {
    width: 100%;
  }

  // .hero-section h1 {
  //   font-size: 2em;
  // }
  //
  // .hero-section p {
  //   font-size: 1em;
  // }
}

@media (max-width: 480px) {
  // .cta-button {
  //   padding: 8px 16px;
  // }

  // .hero-section {
  //   padding: 30px 10px;
  // }

  // .hero-section h1 {
  //   font-size: 1.5em;
  // }
  //
  // .hero-section p {
  //     font-size: 0.9em;
  // }
}
